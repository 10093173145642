<template>
  <onboard-wrapper title="Settings">
    <validation-observer ref="onboardSettingObserver" style="width: 100%">
      <settings-screen
        @remunerationChanged="remunerationChanged = true"
        @policyChanged="policyChanged = true"
      />
      <validation-provider
        #default="{ errors }"
        name="number of licenses"
        rules="required|positive"
      >
        <v-text-field
          outlined
          :error-messages="errors"
          v-model.number="maximumAccount"
          label="Number of Licenses"
          prepend-inner-icon="mdi-map-marker"
        ></v-text-field>
      </validation-provider>
      <v-btn
        class="mt-3"
        block
        color="primary"
        elevation="5"
        :loading="loading"
        large
        @click="submit()"
      >
        Submit
      </v-btn>
    </validation-observer>
  </onboard-wrapper>
</template>
<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import OnboardWrapper from "../../components/OnboardWrapper.vue";
import SettingsScreen from "../../components/SettingsScreen.vue";

import "firebase/auth";
import "firebase/firestore";
export default {
  components: {
    OnboardWrapper,
    SettingsScreen,
  },
  data() {
    return {
      remunerationChanged: false,
      policyChanged: false,
      maximumAccount: null,
      loading: false,
      errorMessage: "",
    };
  },
  computed: {
    ...mapState({
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },
  methods: {
    async submit() {
      try {
        if (this.remunerationChanged && this.policyChanged) {
          const valid = await this.$refs.onboardSettingObserver.validate();
          if (valid) {
            console.log("login");
            this.loading = true;
            this.errorMessage = "";
            await firebase
              .firestore()
              .collection("Organizations")
              .doc(this.currentOrganization.id)
              .update({
                max_account: this.maximumAccount,
                setup: true,
              });
            this.loading = false;
            this.$router.push("/onboard/payment");
          }
        } else {
          this.$toast.error("Please set your policy and plan");
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.errorMessage = error.message
          ? error.message
          : "Failed to complete setup";
        this.$toast.error(this.errorMessage);
      }
    },
  },
};
</script>
