<template>
  <v-row>
    <v-col class="onboard-design">
      <!-- <img
        class="load-img"
        :src="require('../assets/images/onboard_image.png')"
      /> -->
    </v-col>
    <v-col class="d-flex flex-column justify-center align-center">
      <div
        class="d-flex flex-column justify-center align-center"
        style="width: 70%"
      >
        <v-img
          v-if="showLogo"
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="50"
          width="200"
        />
        <h2 class="mb-3">{{ title }}</h2>
        <slot></slot>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Truya",
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.onboard-design {
  /* background-color: rgba(0, 120, 215, 0.5); */
  background-image: url("../assets/images/onboard_image.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0.5; */
}

.load-img {
  width: 70%;
  max-width: 500px;
}
</style>
